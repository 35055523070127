<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import { authStore } from '@/store/auth.module';

// const { layoutConfig, onMenuToggle } = useLayout();

const { onMenuToggle } = useLayout();

const usertore = authStore();
const { getUser } = storeToRefs(usertore);
const user = ref(getUser);

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();

onMounted(() => {
	bindOutsideClickListener();
});
onBeforeUnmount(() => {
	unbindOutsideClickListener();
});

const logoUrl = computed(() => {
	// return `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
	return `layout/images/logo.png`;
});

const onTopBarMenuButton = () => {
	topbarMenuActive.value = !topbarMenuActive.value;
};
const onSettingsClick = () => {
	usertore.logout();
	topbarMenuActive.value = false;
	router.push('/auth/login');
};
const topbarMenuClasses = computed(() => {
	return {
		'layout-topbar-menu-mobile-active': topbarMenuActive.value
	};
});

const bindOutsideClickListener = () => {
	if (!outsideClickListener.value) {
		outsideClickListener.value = (event) => {
			if (isOutsideClicked(event)) {
				topbarMenuActive.value = false;
			}
		};
		document.addEventListener('click', outsideClickListener.value);
	}
};
const unbindOutsideClickListener = () => {
	if (outsideClickListener.value) {
		document.removeEventListener('click', outsideClickListener);
		outsideClickListener.value = null;
	}
};
const isOutsideClicked = (event) => {
	if (!topbarMenuActive.value) return;

	const sidebarEl = document.querySelector('.layout-topbar-menu');
	const topbarEl = document.querySelector('.layout-topbar-menu-button');
	if (!sidebarEl || !topbarEl) return true;
	return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img :src="logoUrl" alt="logo" />
		</router-link>

		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
			<i class="pi pi-ellipsis-v"></i>
		</button>

		<div class="layout-topbar-menu" :class="topbarMenuClasses">
			<!-- <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
				<i class="pi pi-calendar"></i>
				<span>Calendar</span>
			</button>
			<button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
				<i class="pi pi-user"></i>
				<span>Profile</span>
			</button> -->
			<div class="p-link layout-topbar-button">
				<i class="pi pi-user"></i> <span style="display: block !important" class="ml-1 font-bold">Welcome {{ user ? user.name : '' }}</span>
			</div>
			<button @click="onSettingsClick()" class="p-link layout-topbar-button">
				<i class="pi pi-sign-out"></i>
				<span>Sign out</span>
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
